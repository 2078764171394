import '../styles/Landing.css';
import { DataGrid } from '@mui/x-data-grid';
import React, { useState } from 'react';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { DateTime } from "luxon";

function InactiveArchive() {
    const [instrument, setInstrument] = useState(null);
    const [instruments, setInstruments] = useState([]);
    const [calibrations, setCalibrations] = useState([]);
    const [types, setTypes] = useState([]);
    const [type, setType] = useState(null);
    const [locations, setLocations] = useState([]);
    const [location, setLocation] = useState(null);
    const [multicompany, setMulticompany] = useState(false)
    const [fullList, setFullList] = useState([]);
    const [state, setState] = useState({department: null, assigned_to: '', out_of_service_date: '', removed_for_calibration: '', comments: ''});
    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [token, setToken] = useState();

    const navigate = useNavigate();

    const api_url = 'https://func-bv-calibrations.azurewebsites.net/api/calibrations/';

    React.useEffect(() => {
        if(isAuthenticated) {
            fetchAllInstruments();
        }
        // eslint-disable-next-line
    }, [isAuthenticated]);

    function fetchAllInstruments() {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };

        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then((response) => {
            setInstruments([{"item_number": "Loading...", "id": 1}])
            setToken(response);
            fetch((api_url + 'instruments'), {headers: {Authorization: response.idToken}, method: 'GET'}).then((response2) => response2.json())
            .then((req_ret) => {
                if(Array.isArray(req_ret) && req_ret.length > 0){
                    req_ret.forEach((item) => {
                        if(item.last_calibrated_date !== '' && item.subtype !== 'REF/Cal as Req'){
                            var date = new Date(item.last_calibrated_date + 'T00:00:00.000');
                            date.setMonth(date.getMonth()+ parseInt(item.frequency, 10))
                            item['due_date'] = date.toISOString().substring(0, 10)
                        }
                    })

                    var filteredInstruments = []; // array to hold instrument records that we intend to KEEP
                    for(let i = 0; i < req_ret.length; i++ ) { // loop through every instrument in the list once
                        if(req_ret[i].status !== 'Active') {
                            let idCheck = req_ret[i].id
                            var pushed = false;
                            for(let j = 0; j < req_ret.length; j++) {
                                if(j !== i && (req_ret[j].item_number === req_ret[i].item_number && req_ret[j].type === req_ret[i].type && req_ret[j].location === req_ret[i].location) && (req_ret[j].status === 'Active' || new Date(req_ret[j].out_of_service_date) > new Date(req_ret[i].out_of_service_date)) && !filteredInstruments.some((e) => e.id === idCheck)) {
                                    filteredInstruments.push(req_ret[i]) // add to the filtered instruments if there is a active status duplicate or there is a duplicate with a more recent out of service date
                                    pushed = true;
                                }
                            }

                            // if the current instrument has not already been added to the filtered list check to make sure if it has been inactive for 5 years or longer
                            if(pushed === false) {
                                var now = DateTime.now(); // luxon DateTime now()
                                var OOSDate = DateTime.fromISO(req_ret[i].out_of_service_date); // luxon DateTime object of the out of service date

                                if(now.diff(OOSDate, 'years').toObject().years >= 5 && !filteredInstruments.some((e) => e.id === idCheck)) {
                                    filteredInstruments.push(req_ret[i])
                                }
                            }
                        }
                    }

                    req_ret = filteredInstruments; // set the instrument list to our now filtered list
                    setInstruments(req_ret)
                    setFullList(req_ret)

                    try {
                        if(sessionStorage.getItem("location") === null && sessionStorage.getItem("type") !== null) {
                            if(type === null) { setType(JSON.parse(sessionStorage.getItem("type"))) }
                            setInstruments(req_ret.filter(val => val.type === JSON.parse(sessionStorage.getItem("type")).value && val.company === JSON.parse(sessionStorage.getItem("type")).company))
                        } else if(sessionStorage.getItem("location") !== null && sessionStorage.getItem("type") === null) {
                            if(location === null) { setLocation(JSON.parse(sessionStorage.getItem("location")))}
                            setInstruments(req_ret.filter(val => val.location === JSON.parse(sessionStorage.getItem("location")).value && val.company === JSON.parse(sessionStorage.getItem("location")).company))
                        } else if(sessionStorage.getItem("location") !== null && sessionStorage.getItem("type") !== null) {
                            if(type === null) { setType(JSON.parse(sessionStorage.getItem("type"))) }
                            if(location === null) { setLocation(JSON.parse(sessionStorage.getItem("location")))}
                            setInstruments(req_ret.filter(val => (val.location === JSON.parse(sessionStorage.getItem("location")).value) && val.type === JSON.parse(sessionStorage.getItem("type")).value && val.company === JSON.parse(sessionStorage.getItem("type")).company && val.company === JSON.parse(sessionStorage.getItem("location")).company))
                        }
                        sessionStorage.removeItem('type');
                        sessionStorage.removeItem('location');
                    } catch(e) {
                        alert("Could not apply saved filters! Please reselect desired filters.")
                    }

                    if(instrument !== null) {
                        req_ret.forEach((inst) => {
                            if(inst?.id === instrument?.id) {
                                setInstrument(inst)
                                setState({department: inst?.department !== '' ? {label: inst?.department, value: inst?.department} : null, assigned_to: inst?.assigned_to, out_of_service_date: inst?.out_of_service_date, removed_for_calibration: '', comments: inst?.comments})
                            }
                        })
                    }

                    fetch((api_url + 'locations'), {headers: {Authorization: response.idToken}, method: 'GET'}).then((response) => response.json())
                    .then((json) => {
                        if(json.map((loc)=>{return loc.company}).filter((v,i,a) => { return a.indexOf(v)===i}).length > 1){
                            setMulticompany(true)
                            setLocations(json.map(res=>{
                                res['label'] = res.name + ' - ' + res.company_name; res['value'] = res.name; return res
                            }))
                        } else {
                            setMulticompany(false)
                            setLocations(json.map(res=>{res['label'] = res.name; res['value'] = res.name; return res}))
                        }
                        return (json.map((loc)=>{return loc.company}).filter((v,i,a) => { return a.indexOf(v)===i}).length > 1)
                    }).then((mc) => {
                        fetch((api_url + 'types'), {headers: {Authorization: response.idToken}, method: 'GET'}).then((response) => response.json())
                        .then((json) => setTypes(json.map(res=>{res['label'] = (mc ? res.name + ' - ' + res.company_name : res.name); res['value'] = res.name; return res}))).catch((error) => {
                            alert(error)
                        });
                    }).catch((error) => {
                        alert(error)
                    });
                } else {
                    setInstruments([])
                }
            }).catch((error) => {
                console.log(error)
                alert(error)
            });
        }).catch((e) => {
            console.log(e)
            alert(e)
        });

    }

    const instrument_columns = [
        { field: 'item_number', headerName: 'Item Number', width: 150 },
        { field: 'type', headerName: 'Type', width: 100 },
        { field: 'subtype', headerName: 'Subtype', width: 100 },
        { field: 'due_date', headerName: 'Due Date', width: 200 },
        { field: 'status', headerName: 'Status', width: 100 },
        { field: 'department', headerName: 'Department', width: 200 },
        { field: 'serial_number', headerName: 'SN', width: 200 },
        { field: 'location', headerName: 'Location', width: 200 },
        { field: 'company_name', headerName: 'Company', width: 200 },
    ];

    const calibration_columns = [
        { field: 'manufacturer', headerName: 'Manufacturer', width: 200 },
        { field: 'serial_number', headerName: 'SN', width: 200 },
        { field: 'date_calibrated', headerName: 'Calibrated', width: 125 },
        { field: 'due_date', headerName: 'Due', width: 125},
        { field: 'calibrated_by', headerName: 'Calibrated By', width: 200 },
        { field: 'removed_for_calibration', headerName: 'Removed for Cal', width: 200 },
        { field: 'approved_by', headerName: 'Approved By', width: 200 }
    ];

    const handleInstrumentSelection = e => {
        setInstrument(e.row)
        setState({department: e.row?.department !== '' ? {label:e.row?.department, value:e.row?.department} : null, assigned_to: e.row?.assigned_to, out_of_service_date: e.row?.out_of_service_date, removed_for_calibration: '', comments: e.row?.comments})
        setCalibrations([{"manufacturer": "Loading...", "id": 1}])
        fetchInstrumentCalibrations(e.row)
    };

    function fetchInstrumentCalibrations(passedInstrument) {
        fetch((api_url + 'instrument-cals/' + passedInstrument?.id), {headers: {Authorization: token.idToken}, method: 'GET'}).then((response) => {
            if(response.status === 200) {
                return response.json()
            } else if(response.status === 401) {
                alert("You're session has expired, please log out and log back in!")
                instance.logoutRedirect().catch(e => { console.error(e);});
                return false
            }
        }).then((json) => {
            if(json){
                var cal_list = [];
                var initial_cals = json.filter(res=>res.instrument_id === passedInstrument?.id);
                for(var i = 0; i < initial_cals.length; i++) {
                    var date = new Date(initial_cals[i].date_calibrated + 'T00:00:00.000');
                    date.setMonth(date.getMonth()+ parseInt(passedInstrument?.frequency, 10))
                    let temp = {...initial_cals[i], due_date: passedInstrument?.subtype !== 'REF/Cal as Req'? date.toISOString().substring(0, 10) : '',manufacturer: passedInstrument?.manufacturer, serial_number: passedInstrument?.serial_number}
                    cal_list.push(temp)
                }
                setCalibrations(cal_list)
            }
        }).catch((error) => {
                alert(error)
        });
    }

    const handleCalibrationSelection = e => {
        sessionStorage.setItem("instrument", instrument.id);
        sessionStorage.setItem("calibration", e.row?.id);
        if(type !== null) { sessionStorage.setItem("type", JSON.stringify(type)) }
        if(location !== null) { sessionStorage.setItem("location", JSON.stringify(location)) }
        navigate('/view-calibration', { replace: true, state: {redirect: '/inactive-archive'} }); // pass a redirect variable so the view cal page knows where to redirect us back to
    };

    const onDropdownChange = (value, action) => {
        if(value !== null){
            if(action.name === 'type') {
                if(location === null) {
                    console.log(value)
                    setInstruments(fullList.filter(val => val.type === value.value && val.company === value.company))
                } else {
                    setInstruments(fullList.filter(val => (val.type === value.value && val.location === location.value && val.company === value.company && val.company === location.company)))
                }
                setType(value)
            } else {
                if(type === null) {
                    setInstruments(fullList.filter(val => val.location === value.value && val.company === value.company))
                } else {
                    setInstruments(fullList.filter(val => (val.location === value.value && val.type === type.value && val.company === value.company && val.company === type.company)))
                }
                setLocation(value)
            }
        } else {
            if(action.name === 'type') {
                if(location === null) {
                    setInstruments(fullList)
                } else {
                    setInstruments(fullList.filter(val => val.location === location.value && val.company === location.company))
                }
                setType(null)
            } else {
                if(type === null) {
                    setInstruments(fullList)
                } else {
                    setInstruments(fullList.filter(val => val.type === type.value && val.company === type.company))
                }
                setLocation(null)
            }
        }
    }

    return (
        <>
        <br /><br /><br />
        <h2 id="page-header">Inactive Instrument List:</h2>
        <Select className="type-select"
            isClearable={true}
            options={types}
            placeholder="Select a Type..."
            name='type'
            onChange={onDropdownChange}
            value={type}
        />
        {token?.idTokenClaims?.groups.some((element) => process.env.REACT_APP_ADMIN_GROUPS.includes(element)) ? <>
            <Select className="type-select"
                isClearable={true}
                options={locations}
                placeholder="Select a Location..."
                name='location'
                onChange={onDropdownChange}
                value={location}
            />
        </> : <></>}
        <br /><br />
        <div className='landing-grid'>
            <DataGrid
            rows={instruments}
            columns={instrument_columns}
            columnVisibilityModel={{
                company_name: multicompany
            }}
            disableMultipleSelection={true}
            onCellClick={handleInstrumentSelection}
            initialState={{
                sorting: {
                  sortModel: [{ field: 'location', sort: 'asc' }],
                },
            }}
            />
        </div>
        <br /><br />
        {instrument !== null ? <div className='instrument-overview'>
            <div className='instrument-left'>
                <h2>Instrument Overview:</h2><br />
                <h4>Item Number: {instrument?.item_number}</h4>
                <h4>Type: {instrument?.type}</h4>
                <h4>Sub Type: {instrument?.subtype}</h4>
                <h4>Department: {instrument?.department}</h4>
                <h4>Assigned To: {instrument?.assigned_to}</h4>
                <h4>Manufacturer: {instrument?.manufacturer}</h4>
                <h4>Model: {instrument?.model}</h4>
                <h4>Range: {instrument?.range}</h4>
                <h4>Serial Number: {instrument?.serial_number}</h4>
            </div>
            <div className='instrument-right'>
                <h2>Instrument Status: <span style={instrument.status === 'Active' ? {color:'green'} : {color:'red'}}>{instrument.status}</span></h2><br />
                <h4>In Service Date: {instrument.in_service_date}</h4>
                <h4>Out of Service Date: {instrument?.out_of_service_date} </h4>
                <h4>Frequency: {instrument.frequency}</h4>
                <h4>Date Calibrated: {instrument.last_calibrated_date}</h4>
                <h4>Due: {instrument.due_date}</h4>
                <h4>Removed for Calibration: {instrument?.removed_for_calibration} </h4>
                <h4>Comments: </h4>
                <textarea readOnly className='instrument-comments' id="instrument-comments" name="comments" value={state.comments}></textarea><br/>
            </div>
        </div> : <></>}
        <br /><br />
        {instrument !== null ? <>
            <div className='landing-grid'>
                    <DataGrid
                    rows={calibrations}
                    columns={calibration_columns}
                    disableMultipleSelection={true}
                    initialState={{
                        sorting: {
                          sortModel: [{ field: 'date_calibrated', sort: 'desc' }],
                        },
                    }}
                    onCellClick={handleCalibrationSelection}/>
            </div>
            <br />
        </> : <></>}
        </>
    );
}

export default InactiveArchive;