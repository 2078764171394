import { Navbar, Nav} from "react-bootstrap";
import { NavLink } from 'react-router-dom';
import logo from "../media/hurricane.png";
import { AuthButton } from "./AuthButton";
import { useMsal } from "@azure/msal-react";

function CalibrationsNav() {

  const { accounts } = useMsal();

  const adminOnly = (accounts[0]?.idTokenClaims?.groups.some((element) => process.env.REACT_APP_ADMIN_GROUPS.includes(element)) && !accounts[0]?.idTokenClaims?.roles.includes('location_user'))

  return (
    <>
        <Navbar id='navbar' bg="dark" fixed="top" variant="dark" expand="lg" collapseOnSelect>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Item>
                  <Navbar.Brand className="navbar-logo"><NavLink to="/"><img alt="" src={logo} width="30" id="logo" /></NavLink></Navbar.Brand>
                  <Navbar.Text id="brand-name">ISS Calibrations</Navbar.Text>
              </Nav.Item>
                {accounts.length !== 0 ? <>
                    {accounts[0]?.idTokenClaims?.groups.includes(process.env.REACT_APP_AUDIT_GROUP) ? <></> : <Nav.Item className="page-selection"><NavLink className="dropdownLink" to="/add-instrument">Add Instrument</NavLink></Nav.Item>}
                    <Nav.Item className="page-selection"><NavLink className="dropdownLink" to="/">Instrument Details</NavLink></Nav.Item>
                    <Nav.Item className="page-selection"><NavLink className="dropdownLink" to="/inactive-archive">Inactive Instrument Archive</NavLink></Nav.Item>
                    {(adminOnly && !accounts[0]?.idTokenClaims?.groups.includes(process.env.REACT_APP_AUDIT_GROUP)) ? <Nav.Item className="page-selection"><NavLink className="dropdownLink" to="/edit-instrument">Edit Instrument</NavLink></Nav.Item> : <></>}
                </>:<></>}
            </Nav>
            <Nav className="right-nav">
              {accounts.length !== 0 ? <Nav.Item className="signedIn"><Navbar.Text> Signed in as: <span id="username">{accounts[0].name ? accounts[0].name : "UNKNOWN"}</span> </Navbar.Text></Nav.Item> : <></>}
              <Nav.Item><AuthButton /></Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
    </>
  );
}

export default CalibrationsNav;