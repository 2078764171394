import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { CircularProgress } from '@mui/material';
import { loginRequest } from "../authConfig";
import { useNavigate } from 'react-router-dom';
import '../styles/Instruments.css';
import '../styles/App.css';
import { DataGrid } from '@mui/x-data-grid';

function EditInstrument() {
    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [token, setToken] = useState();
    const [state, setState] = useState({type: null, subtype: null, item_number: "", department: null, assigned_to: "", manufacturer: "", model: "", range: "", serial_number: "", rated_accuracy: '', frequency: "", in_service_date: new Date().toJSON().slice(0, 10), comments: "", location: ''});
    const [submit, setSubmit] = useState(false);
    const [userResponse, setUserResponse] = useState('');
    const [instrument, setInstrument] = useState(null);
    const [instruments, setInstruments] = useState([]);
    const [instrumentCount, setInstrumentCount] = useState(null);
    const [existingInstruments, setExistingInstruments] = useState([])
    const [multicompany, setMulticompany] = useState(false)
    const [types, setTypes] = useState([])
    const [subtypes, setSubtypes] = useState([])
    const [locations, setLocations] = useState([])
    const [departments, setDepartments] = useState([])

    const [allTypes, setAllTypes] = useState([])
    const [allLocations, setAllLocations] = useState([])
    const [allDepartments, setAllDepartments] = useState([])
    const [allSubtypes, setAllSubtypes] = useState([])

    const navigate = useNavigate();
    
    const api_url = 'https://func-bv-calibrations.azurewebsites.net/api/calibrations/';
    
    const existing_columns = [
        { field: 'item_number', headerName: 'Item Number', width: 150},
        { field: 'type', headerName: 'Type', width: 100},
        { field: 'subtype', headerName: 'Subtype', width: 100 },
        { field: 'location', headerName: 'Location', width: 150 },
        { field: 'company_name', headerName: 'Company', width: 200 },
    ];

    const instrument_columns = [
        { field: 'item_number', headerName: 'Item Number', width: 150 },
        { field: 'type', headerName: 'Type', width: 100 },
        { field: 'subtype', headerName: 'Subtype', width: 100 },
        { field: 'status', headerName: 'Status', width: 100 },
        { field: 'department', headerName: 'Department', width: 200 },
        { field: 'serial_number', headerName: 'SN', width: 200 },
        { field: 'location', headerName: 'Location', width: 200 },
        { field: 'company_name', headerName: 'Company', width: 200 },
    ];

    React.useEffect(() => {
        if(isAuthenticated) { 
            const request = {
                ...loginRequest,
                account: accounts[0]
            };
    
            // Silently acquires an access token which is then attached to a request for Microsoft Graph data
            instance.acquireTokenSilent(request).then((response) => {
                setToken(response);
                fetchInstruments(response);
                // In here we set allLocations, allTypes etc then we will filter it down further based on company when an instrument is selected.
                fetch((api_url + 'locations'), {headers: {Authorization: response.idToken}, method: 'GET'}).then((response) => response.json())
                .then((json) => {
                    if(json.map((loc)=>{return loc.company}).filter((v,i,a) => { return a.indexOf(v)===i}).length > 1){
                        setMulticompany(true)
                        setAllLocations(json.map(res=>{res['label'] = res.name + ' - ' + res.company_name; res['value'] = res.name; return res}))
                    } else {
                        setMulticompany(false)
                        setAllLocations(json.map(res=>{res['label'] = res.name; res['value'] = res.name; return res}))
                    }
                    return (json.map((loc)=>{return loc.company}).filter((v,i,a) => { return a.indexOf(v)===i}).length > 1)
                }).then((mc) => {
                    fetch((api_url + 'types'), {headers: {Authorization: response.idToken}, method: 'GET'}).then((response) => response.json())
                    .then((json) => setAllTypes(json.map(res=>{res['label'] = (mc ? res.name + ' - ' + res.company_name : res.name); res['value'] = res.name; return res}))).catch((error) => {
                        alert(error)
                    });
                    fetch((api_url + 'subtypes'), {headers: {Authorization: response.idToken}, method: 'GET'}).then((response) => response.json())
                    .then((json) => setAllSubtypes(json.map(res=>{res['label'] = (mc ? res.name + ' - ' + res.company_name : res.name); res['value'] = res.name; return res}))).catch((error) => {
                        alert(error)
                    });
                    fetch((api_url + 'departments'), {headers: {Authorization: response.idToken}, method: 'GET'}).then((response) => response.json())
                    .then((json) => setAllDepartments(json.map(res=>{res['label'] = (mc ? res.name + ' - ' + res.company_name : res.name); res['value'] = res.name; return res}))).catch((error) => {
                        alert(error)
                    });
                }).catch((error) => {
                    alert(error)
                });
            }).catch((e) => {
                instance.acquireTokenPopup(request).then((response) => {
                    setToken(response);
                });
            });
        }
        // eslint-disable-next-line
      }, [isAuthenticated]);

    function fetchInstruments(internalToken) {
        setInstruments([]);
        fetch((api_url + 'instruments'), {headers: {Authorization: internalToken.idToken}, method: 'GET'}).then((response) => response.json())
        .then((json) => {
            setInstruments(json)
            setInstrumentCount(json.length)
        }).catch((error) => {
            alert(error)
        });
    }
  
    function RequestAccessToken() {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };

        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then((response) => {
            setToken(response);
            // console.log(response);
        }).catch((e) => {
            instance.acquireTokenPopup(request).then((response) => {
                setToken(response);
            });
        });
    }

    const onDropdownChange = (value, action) => {
        setState(prevState => ({
            ...prevState,
            [action.name]: value === null ? '' : value
        }));

        if(action.name === 'type' && value !== null) {
            if(state.location?.value !== null && state.location?.value !== undefined && state.location?.value !== '') {
                setExistingInstruments(instruments.filter(res => res.type === value.value && res.location === state.location?.value && res.company === state.location?.company  && res.company === value.company && res.status === "Active" ))
            } else {
                setExistingInstruments(instruments.filter(res=>res.type === value.value && res.status === "Active" && res.company === value.company))
            }

            setSubtypes(allSubtypes.filter(res => res.type === value.value && res.company === value?.company))
            setState(prevState => ({
                ...prevState,
                subtype: null
            }));

        } else if(action.name === 'type' && value === null) {
            setSubtypes([])
            setExistingInstruments([])
            setState(prevState => ({
                ...prevState,
                subtype: null,
                rated_accuracy: '',
                frequency: ''
            }));
        } else if(action.name === 'subtype' && value !== null) {
            setState(prevState => ({
                ...prevState,
                frequency: value['frequency']
            }));
        } else if(action.name === 'subtype' && value === null) {
            setState(prevState => ({
                ...prevState,
                frequency: ''
            }));
        } else if(action.name === 'location' && value !== null) {
            setExistingInstruments(instruments.filter(res=>res.location === value.value && res.company === value.company && res.status === "Active"))
        } else if(action.name === 'location' && value === null) {
            if(state.type?.value !== null && state.type?.value !== undefined && state.type?.value !== '') {
                fetch((api_url + 'instruments'), {headers: {Authorization: token.idToken}, method: 'GET'}).then((response) => response.json())
                .then((json) => {
                    setInstrumentCount(json.length)
                    setExistingInstruments(json.filter(res=>res.type === state.type.value && res.status === "Active" && res.company === state.type?.company))
                }).catch((error) => {
                    alert(error)
                });
            }
        }
    }

    const handleChange = e => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleHome = e => {
        navigate('/', { replace: true });
    };

    const handleInstrument = e => {
        setSubmit(true)
        setUserResponse('')
        RequestAccessToken()
        var param = {"type": state.type?.value ? state.type?.value : '', "subtype": state.subtype?.value ? state.subtype?.value : '', "item_number": state.item_number, "department": state.department?.value ? state.department?.value : '', "assigned_to": state.assigned_to, "manufacturer": state.manufacturer, "model": state.model, "range": state.range, "serial_number": state.serial_number, "rated_accuracy": state.rated_accuracy, "frequency": state.frequency, "in_service_date": state.in_service_date, "comments": state.comments, "status": "Active"}
        if(token?.idTokenClaims?.groups.some((element) => process.env.REACT_APP_ADMIN_GROUPS.includes(element)) || locations.length > 1) {
            param['location'] = state.location?.value
            param['company'] = state.location?.company
        }
        var inUse = false
        var snInUse = false
        if(token?.idTokenClaims?.groups.some((element) => process.env.REACT_APP_ADMIN_GROUPS.includes(element)) || locations.length > 1) {
            instruments.forEach((item) => {
                if(item?.item_number === state.item_number && item?.location === state.location?.value && item?.location?.company === state.location?.company) { inUse = true }
                if(item?.serial_number === state.serial_number && item?.manufacturer === state.manufacturer && item?.location === state.location?.value && item?.location?.company === state.location?.company) { snInUse = true }
            })
        } else {
            instruments.forEach((item) => {
                if(item?.item_number === state.item_number && item?.location === state.location?.value && state.item_number !== instrument?.item_number) { inUse = true }
                if(item?.serial_number === state.serial_number && item?.manufacturer === state.manufacturer && item?.location === state.location?.value && state.serial_number !== instrument?.serial_number) { snInUse = true }
            })
        }
        if(snInUse) {
            setUserResponse('Error! Serial Number already in use!')
            setSubmit(false)
        } else if(isNaN(parseInt(state.frequency, 10)) || (state.subtype?.frequency && parseInt(state.frequency, 10) > parseInt(state.subtype?.frequency, 10))) {
            if(isNaN(parseInt(state.frequency))) {
                setUserResponse('Error! Frequency is not a numerical value, try entering a number!')
            } else {
                setUserResponse('Error! Frequency is higher than subtype default!') 
            }
            setSubmit(false)
        } else if(((state.department !== null && state.department !== '') ? ![state.location?.company, state.type?.company, state.subtype?.company, state.department?.company].every((element) => { return element === state.location?.company; }): ![state.location?.company, state.type?.company, state.subtype?.company].every((element) => { return element === state.location?.company; })) && multicompany) {
            setUserResponse('Error! The type, subtype, department, and location must all be from the same company!')
            setSubmit(false)
        } else if(!inUse){
            fetch((api_url + 'instruments/' + instrument?.id), {headers: {Authorization: token.idToken}, method: 'PUT', body: JSON.stringify(param)}).then((response) => {
                if(response.status === 200) {
                    setState({type: null, subtype: null, item_number: "", department: null, assigned_to: "", manufacturer: "", model: "", range: "", serial_number: "", rated_accuracy: '', frequency: "", in_service_date: new Date().toJSON().slice(0, 10), comments: "", location: ''})
                    setInstrument(null)
                    setExistingInstruments([])
                    setUserResponse('Success!')
                    setSubmit(false)
                    fetchInstruments(token)
                } else {
                    setUserResponse('Error! Try again later.')
                    setSubmit(false)
                }
            }).catch((error) => {
                setUserResponse('Error! Try again later.')
                setSubmit(false)
                alert(error)
            });
        } else {
            setUserResponse('Error! Item Number already in use!')
            setSubmit(false)
        }
    };

    function generateSN() {
        if(state.type === null || state.type === '') { alert('Choose a type before generating a serial number!') } else {
            let date = new Date();
            let value = (date.getMonth() + 1).toString().padStart(2, '0') + date.getDate().toString().padStart(2, '0') + date.getFullYear().toString().substr(-2) + instrumentCount.toString()
            setState(prevState => ({
                ...prevState,
                serial_number: value
            }));
        }
    };

    const selectInstrument = e => {
        // setExistingInstruments(instruments.filter(res => res.type === e.row?.type ))
        // get type, subtype, departments based on company when selecting an instrument
        setTypes(allTypes.filter(res=>res.company === e.row?.company))
        setSubtypes(allSubtypes.filter(res => res.type === e.row?.type && res.company === e.row?.company))
        setDepartments(allDepartments.filter(res=>res.company === e.row?.company))
        setLocations(allLocations.filter(res=>res.company === e.row?.company))
        setInstrument(e.row)
        setState({type: e.row?.type ? allTypes.filter(row => row.name === e.row?.type && row.company === e.row?.company)[0] : null, subtype: e.row?.subtype ? allSubtypes.filter(row => row.name === e.row?.subtype && row.type === e.row?.type && row.company === e.row?.company)[0] : null, department: e.row?.department ? allDepartments.filter(row => row.name === e.row?.department && row.company === e.row?.company)[0] : null, item_number: e.row?.item_number, assigned_to: e.row?.assigned_to, location: e.row?.location ? allLocations.filter(row => row.name === e.row?.location && row.company === e.row?.company)[0] : null, manufacturer: e.row?.manufacturer, model: e.row?.model, range: e.row?.range, serial_number: e.row?.serial_number, frequency: e.row?.frequency, in_service_date: e.row?.in_service_date, rated_accuracy: e.row?.rated_accuracy, comments: e.row?.comments})

        if(e.row?.location !== null && e.row?.location !== undefined && e.row?.location !== '') {
            setExistingInstruments(instruments.filter(res => res.type === e.row?.type && res.location === e.row?.location && res.company === e.row?.company && res.status === "Active" ))
        } else {
            setExistingInstruments(instruments.filter(res=>res.type === e.row?.type && res.status === "Active"))
        }
        
    }
  
    return (
        <> 
        <br /><br /><br /><br />
        <div className='instrument-grid'>
            <DataGrid 
                rows={instruments} 
                columns={instrument_columns} 
                columnVisibilityModel={{
                    company_name: multicompany
                }}
                disableMultipleSelection={true}
                onCellClick={selectInstrument}
                initialState={{
                    sorting: {
                      sortModel: [{ field: 'location', sort: 'asc' }],
                    },
                }}
            />
        </div>
        { instrument !== null ? <>
        <div className="divider-div">
            <div className="instrument-container">
                <br /><br />
                <h1 className='page-header'>{instrument?.item_number}</h1>
                <br />
                <div className="flex-container">
                    <div className="column_1">
                        <h5 className="field-name">Type: </h5>
                        <Select className="select-fields" isClearable={true} options={types} 
                            placeholder="Select a Type..." 
                            name='type'
                            onChange={onDropdownChange} 
                            value={state.type}
                        /><br />
                        <h5 className="field-name">Item Number: </h5>
                        <input type="text" id="item_number" name="item_number" value={state.item_number} onChange={handleChange} placeholder='Enter a item number...'/><br/><br/>
                        <h5 className="field-name">Assigned To:</h5>
                        <input type="text" id="assigned_to" name="assigned_to" value={state.assigned_to} onChange={handleChange} placeholder='Enter a name...'/><br/>
                    </div>
                    <div className="column_2">
                        <h5 className="field-name">Sub-type: </h5>
                        <Select className="select-fields" 
                            isClearable={true} 
                            options={subtypes}
                            placeholder="Select a subtype..."  
                            name='subtype'
                            onChange={onDropdownChange} 
                            value={state.subtype}
                        /><br />
                        <h5 className="field-name">Department:</h5>
                        <Select className="select-fields" 
                            isClearable={true} 
                            options={departments}
                            placeholder="Select a department..."  
                            name='department'
                            onChange={onDropdownChange} 
                            value={state.department} 
                        /><br />
                        <h5 className="field-name">Location: </h5>
                        <Select className="select-fields" 
                            isClearable={true} 
                            options={locations}
                            placeholder="Select a location..."  
                            name='location'
                            onChange={onDropdownChange} 
                            value={state.location}
                        /><br />
                    </div>
                </div>
                <br /><br />
                <div className="flex-container">
                    <div className="column_1">
                        <h5 className="field-name">Manufacturer: </h5>
                        <input type="text" id="manufacturer" name="manufacturer" value={state.manufacturer} onChange={handleChange} placeholder='Enter a manufacturer...'/><br/><br/>
                        <h5 className="field-name">Range:</h5>
                        <input type="text" id="range" name="range" value={state.range} onChange={handleChange} placeholder='Enter a range...'/><br/><br />
                        <h5 className="field-name">Frequency:</h5>
                        <input type="text" id="frequency" name="frequency" value={state.frequency} onChange={handleChange} placeholder='Enter a frequency...'/><br/>
                        { state.type?.value === 'Standard' ? <>
                        <br /><h5 className="field-name">Rated Accuracy:</h5>
                        <input type="text" id="rated_accuracy" name="rated_accuracy" value={state.rated_accuracy} onChange={handleChange} placeholder='Enter accuracy...'/><br/><br /></>:<></>}
                    </div>
                    <div className="column_2">
                        <h5 className="field-name">Model: </h5>
                        <input type="text" id="model" name="model" value={state.model} onChange={handleChange} placeholder='Enter the model...'/><br/><br/>
                        <h5 className="field-name">Serial Number:</h5>
                        <input type="text" id="serial_number" name="serial_number" value={state.serial_number} onChange={handleChange} placeholder='Enter the serial number...'/>
                        <Button variant='danger' id='sn-button' onClick={generateSN}>Generate</Button><br/><br/>
                        <h5 className="field-name">In Service Date:</h5>
                        <input type="date" id="in_service_date" name="in_service_date" value={state.in_service_date} onChange={handleChange} /><br/>
                    </div>
                </div>
                <br />
                <div id='comment-box'>
                    <h5 className="field-name">Comments:</h5>
                    <textarea id="comments" name="comments" value={state.comments} onChange={handleChange} placeholder='Enter a comment...'></textarea><br/>
                </div>
                <br />
                <Button variant="danger" onClick={handleInstrument} disabled={submit || state.type === null || state.item_number ==='' || state.frequency === '' || state.manufacturer === '' || state.model === '' || state.range === '' || state.serial_number === '' || (state.type?.value === 'Standard' && state.rated_accuracy === '') || (token?.idTokenClaims?.groups.some((element) => process.env.REACT_APP_ADMIN_GROUPS.includes(element)) && state.location === '')}>{submit ? <CircularProgress size={24}/>: 'Update'}</Button>
                <Button variant="danger" onClick={handleHome} id="home-button">Back to Instrument List</Button>
                <h4 id="user-feeback">{userResponse}</h4>

            </div>
            <div className="used-items">
                <br /><br />
                <h5 className="field-name">Existing Item Numbers:</h5>
                <DataGrid 
                    className="existing-numbers" 
                    columns={existing_columns}
                    columnVisibilityModel={{
                        company_name: multicompany
                    }}
                    rows={existingInstruments} 
                    initialState={{
                        sorting: {
                          sortModel: [{ field: 'location', sort: 'asc' }],
                        },
                    }}
                />
            </div>
        </div>
        </> : <></>}
        </>
    );
}

export default EditInstrument;