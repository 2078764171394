import React, { useState } from 'react';
import DataGrid from 'react-data-grid';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import '../styles/AddCalibration.css';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

function ViewCalibration() {
    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [token, setToken] = useState();
    const [instrument, setInstrument] = useState()
    const [calibration, setCalibration] = useState()
    const [pdf, setPdf] = useState(<></>)
    const state = useLocation();
    const navigate = useNavigate();
    
    const api_url = 'https://func-bv-calibrations.azurewebsites.net/api/calibrations/';

    const columns = [
        { key: 'measurement_type', name: 'Measurement Type' },
        { key: 'cal_point', name: 'Cal Point' },
        { key: 'initial', name: 'Measurement' },
        { key: 'adjusted', name: 'Adjusted' },
        { key: 'comments', name: 'Comments' }
    ];

    const cal_columns = [
        { key: 'standard_used', name: 'Standard Used' },
        { key: 'serial_number', name: 'Standard SN' },
        { key: 'rated_accuracy', name: 'Rated Accuracy' },
        { key: 'last_calibrated_date', name: 'Last NIST Cal Date' }
    ];

    React.useEffect(() => {
        if(isAuthenticated) { 
            fetchInformation();
        }
        // eslint-disable-next-line
      }, [isAuthenticated]);
  
      function fetchInformation() {
        let instrument_id = sessionStorage.getItem('instrument')
        let calibration_id = sessionStorage.getItem('calibration')

        if(instrument_id === null || calibration_id === null) {
            navigate('/', { replace: true });
        } else { 
            sessionStorage.removeItem('instrument');
            sessionStorage.removeItem('calibration');

            

            const request = {
                ...loginRequest,
                account: accounts[0]
            };
    
            // Silently acquires an access token which is then attached to a request for Microsoft Graph data
            instance.acquireTokenSilent(request).then((response) => {
                setToken(response);
                fetch((api_url + 'instruments/' + instrument_id), {headers: {Authorization: response.idToken}, method: 'GET'}).then((response) => response.json())
                .then((req_ret) => {
                    setInstrument(req_ret[0])
                }).catch((error) => {
                    alert(error)
                });

                fetch((api_url + 'calibrations/' + calibration_id), {headers: {Authorization: response.idToken}, method: 'GET'}).then((response) => response.json())
                .then((req_ret) => {
                    setCalibration(req_ret[0])
                    if(req_ret[0]?.calibration_sheet_blob !== '') {
                        fetch((api_url + 'blobs/' + req_ret[0].calibration_sheet_blob), {headers: {Authorization: response.idToken}, method: 'GET'}).then((response) => response.blob())
                        .then((blob) => {
                            let url = URL.createObjectURL(blob);
                            setPdf(<object width="100%" style={{height : "85vh"}} type="application/pdf" data={url}>{''}</object>)
                        }).catch((error) => {
                            alert(error)
                        });
                    } else {
                        setPdf(<center><h3>There is no uploaded PDF for this calibration!</h3></center>)
                    }
                }).catch((error) => {
                    alert(error)
                });
            }).catch((e) => {
                instance.acquireTokenPopup(request).then((response) => {
                    setToken(response);
                });
            });
        }
    }

    const handleHome = e => {
        // get redirect location from "state", which is passed on the navigate() call from either the landing or archive page
        var redirect = state.state.redirect;
        navigate(redirect, { replace: true });
    };
  
    return (
        <>  
            <br /><br /><br /><br />
            <div className='cal-container'>
                <div className='left-flex'>
                    <h2>Instrument Information:</h2><br />
                    <h4>Item Number: {instrument?.item_number}</h4>
                    <h4>Item Type: {instrument?.type}</h4>
                    <h4>Item Subtype: {instrument?.subtype}</h4>
                    <h4>Department: {instrument?.department}</h4>
                    <h4>Calibration Frequency: {instrument?.frequency}</h4>
                </div>
                <div className='right-flex'>
                    <h2>Instrument Specifics:</h2><br />
                    <h4>Manufacturer: {instrument?.manufacturer}</h4>
                    <h4>Model: {instrument?.model}</h4>
                    <h4>Range: {instrument?.range}</h4>
                    <h4>Serial Number: {instrument?.serial_number}</h4>
                    <h4>Assigned to: {instrument?.assigned_to}</h4>
                </div>
            </div>
            <br />
            <div className='cal-details'>
                <div className='cal-details-parts'>
                    <h5 className="detail-label">Date Calibrated: {calibration?.date_calibrated}</h5><br/>
                </div>
                <div className='cal-details-spacer'></div>
                <div className='cal-details-parts'>
                    <h5 className="detail-label">Calibration Type: {calibration?.calibration_type}</h5>
                </div>
            </div>
            <br />
            <div className='comment-container'>
                <h2>Calibration Specific Comments:</h2>
                <p>{calibration?.comments}</p>
            </div>
            <br />
            { calibration?.calibration_type === "In House" ?
            <>
                <div className='comment-container'>
                    <h2>Calibration Values:</h2>
                    <DataGrid className="rdg-light" height="200px" columns={columns} rows={calibration?.calibration_values ? JSON.parse(calibration?.calibration_values) : []} />
                </div>
                <br />
                <div className='comment-container'>
                    <h2>Standards Used:</h2>
                    <DataGrid className="rdg-light" height="200px" columns={cal_columns} rows={calibration?.standards_used ? JSON.parse(calibration?.standards_used) : []} />
                </div>
            </> : <>
                <div className='comment-container'>
                    {pdf}
                </div>
            </>} 
            <Button variant="danger" onClick={handleHome} id="landing-button">Back to Instrument List</Button>
        </>
    );
}

export default ViewCalibration;