import './styles/App.css';
import CalibrationsNav from './components/CalibrationsNav';
import LandingPage from './components/LandingPage'
import { Route, Routes } from 'react-router-dom';
import React from 'react';
import AddInstrument from './components/AddInstrument';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal} from "@azure/msal-react";
import AddCalibration from './components/AddCalibration';
import ViewCalibration from './components/ViewCalibration';
import EditInstrument from './components/EditInstrument';
import InactiveArchive from './components/InactiveArchive';

function App() {
  const msal = useMsal();
  const adminOnly = (msal.accounts[0]?.idTokenClaims?.groups.some((element) => process.env.REACT_APP_ADMIN_GROUPS.includes(element)) && !msal.accounts[0]?.idTokenClaims?.roles.includes('location_user'))

  return (
    <>
        <CalibrationsNav />
        <AuthenticatedTemplate>
                {msal.accounts[0]?.idTokenClaims?.groups.includes(process.env.REACT_APP_AUDIT_GROUP) ? <>
                    <Routes>
                        <Route exact path='/' element={<LandingPage />} />
                        <Route exact path='/inactive-archive' element={<InactiveArchive />} />
                        <Route exact path='/view-calibration' element={<ViewCalibration />} />
                    </Routes>
                    </> :
                    <Routes>
                        <Route exact path='/' element={<LandingPage />} />
                        <Route exact path='/add-instrument' element={<AddInstrument />} />
                        <Route exact path='/add-calibration' element={<AddCalibration />} />
                        <Route exact path='/view-calibration' element={<ViewCalibration />} />
                        <Route exact path='/inactive-archive' element={<InactiveArchive />} />
                        {adminOnly ? <Route exact path='/edit-instrument' element={<EditInstrument />} /> : <></>}
                    </Routes>
                }
            <br /><br />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
            <div id="img-background">
                <br /><br />
                <div id='landing-message'>
                    <h1>Welcome to ISS Calibrations!</h1>
                    <h3>Please sign in with your valid ISS credentials!</h3>
                </div>
                
            </div>
        </UnauthenticatedTemplate>
    </>
  );
}

export default App;
