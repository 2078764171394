import '../styles/Landing.css';
import { DataGrid } from '@mui/x-data-grid';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { DateTime } from "luxon";
import { Box, CircularProgress, Grid } from '@mui/material';

function LandingPage() {
    const [instrument, setInstrument] = useState(null);
    const [instruments, setInstruments] = useState([]);
    const [calibrations, setCalibrations] = useState([]);
    const [types, setTypes] = useState([]);
    const [type, setType] = useState(null);
    const [locations, setLocations] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [multicompany, setMulticompany] = useState(false)
    const [location, setLocation] = useState(null);
    const [fullList, setFullList] = useState([]);
    const [state, setState] = useState({department: null, assigned_to: '', out_of_service_date: '', removed_for_calibration: '', comments: ''});
    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [token, setToken] = useState();
    const [loadingState, setLoadingState] = useState({ datagrid: false, calBook: false, calLog: false, blankCal: false })
    const [isViewOnly, setIsViewOnly] = useState(false)

    const navigate = useNavigate();

    const api_url = 'https://func-bv-calibrations.azurewebsites.net/api/calibrations/';

    const generator_url = 'https://func-iss-report-generator-prod.azurewebsites.net/api/generator/calibrations/'

    React.useEffect(() => {
        if(isAuthenticated) {
            fetchAllInstruments();
        }
        // eslint-disable-next-line
    }, [isAuthenticated]);

    function fetchAllInstruments() {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };
        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then((response) => {
            // console.log(response)
            if(response.idTokenClaims?.groups.includes(process.env.REACT_APP_AUDIT_GROUP)) {
                setIsViewOnly(true)
            }
            setInstruments([{"item_number": "Loading...", "id": 1}])
            setToken(response);
            fetch((api_url + 'instruments'), {headers: {Authorization: response.idToken}, method: 'GET'}).then((response2) => response2.json())
            .then((req_ret) => {
                if(Array.isArray(req_ret) && req_ret.length > 0){
                    req_ret.forEach((item) => {
                        if(item.last_calibrated_date !== '' && item.subtype !== 'REF/Cal as Req'){
                            var date = new Date(item.last_calibrated_date + 'T00:00:00.000');
                            date.setMonth(date.getMonth()+ parseInt(item.frequency, 10))
                            item['due_date'] = date.toISOString().substring(0, 10)
                        }
                    })

                    var filteredInstruments = []; // array to hold instrument records that we intend to KEEP
                    for(let i = 0; i < req_ret.length; i++ ) { // loop through every instrument in the list once
                        var count = 0;
                        var newest = i;
                        for(let j = 0; j < req_ret.length; j++) { // loop through the whole array of instruments for each instrument
                            if( j !== i && (req_ret[j].item_number === req_ret[i].item_number && req_ret[j].type === req_ret[i].type && req_ret[j].location === req_ret[i].location) ) { // the instrument is duplicated, not counting itself
                                count += 1;
                                if(req_ret[newest].status !== 'Active') { // if the newest record is INACTIVE
                                    if(req_ret[j].status === 'Active') { // if the inner record is active
                                        newest = j; // the inner record is now our newest
                                    } else if( new Date(req_ret[j].out_of_service_date) > new Date(req_ret[newest].out_of_service_date) ) { // else if the inner record is inactive and the inner record date is newer than the previous newest
                                        newest = j; // set our newest to the inner record
                                    }
                                }
                            }
                        }

                        var now = DateTime.now(); // luxon DateTime now()
                        var OOSDate = DateTime.fromISO(req_ret[i].out_of_service_date); // luxon DateTime object of the out of service date

                        if( (newest === i && req_ret[i].status === 'Active') // if the outer loop record is the newest and it's active
                            || (count === 0 && req_ret[i].status !== 'Inactive') // if there are no duplicates and the outer loop record is not inactive
                            || (newest === i && req_ret[i].status === 'Inactive' && ( now.diff(OOSDate, 'years').toObject().years <= 5 )) // if the outer loop record is the newest and inactive AND the difference in years is less than or equal to 5 years
                        ) {
                            filteredInstruments.push(req_ret[i]) // keep it in the list
                        }
                    }

                    req_ret = filteredInstruments; // set the instrument list to our now filtered list
                    setInstruments(req_ret)
                    setFullList(req_ret)

                    try {
                        if(sessionStorage.getItem("location") === null && sessionStorage.getItem("type") !== null) {
                            if(type === null) { setType(JSON.parse(sessionStorage.getItem("type"))) }
                            setInstruments(req_ret.filter(val => val.type === JSON.parse(sessionStorage.getItem("type")).value && val.company === JSON.parse(sessionStorage.getItem("type")).company))
                        } else if(sessionStorage.getItem("location") !== null && sessionStorage.getItem("type") === null) {
                            if(location === null) { setLocation(JSON.parse(sessionStorage.getItem("location")))}
                            setInstruments(req_ret.filter(val => val.location === JSON.parse(sessionStorage.getItem("location")).value && val.company === JSON.parse(sessionStorage.getItem("location")).company))
                        } else if(sessionStorage.getItem("location") !== null && sessionStorage.getItem("type") !== null) {
                            if(type === null) { setType(JSON.parse(sessionStorage.getItem("type"))) }
                            if(location === null) { setLocation(JSON.parse(sessionStorage.getItem("location")))}
                            setInstruments(req_ret.filter(val => (val.location === JSON.parse(sessionStorage.getItem("location")).value) && val.type === JSON.parse(sessionStorage.getItem("type")).value && val.company === JSON.parse(sessionStorage.getItem("type")).company && val.company === JSON.parse(sessionStorage.getItem("location")).company))
                        }
                        sessionStorage.removeItem('type');
                        sessionStorage.removeItem('location');
                    } catch(e) {
                        alert("Could not apply saved filters! Please reselect desired filters.")
                    }

                    if(instrument !== null) {
                        req_ret.forEach((inst) => {
                            if(inst?.id === instrument?.id) {
                                setInstrument(inst)
                                setState({department: inst?.department !== '' ? {label: inst?.department, value: inst?.department} : null, assigned_to: inst?.assigned_to, out_of_service_date: inst?.out_of_service_date, removed_for_calibration: '', comments: inst?.comments})
                            }
                        })
                    }

                    fetch((api_url + 'locations'), {headers: {Authorization: response.idToken}, method: 'GET'}).then((response) => response.json())
                    .then((json) => {
                        if(json.map((loc)=>{return loc.company}).filter((v,i,a) => { return a.indexOf(v)===i}).length > 1){
                            setMulticompany(true)
                            setLocations(json.map(res=>{
                                res['label'] = res.name + ' - ' + res.company_name; res['value'] = res.name; return res
                            }))
                        } else {
                            setMulticompany(false)
                            setLocations(json.map(res=>{res['label'] = res.name; res['value'] = res.name; return res}))
                        }
                        return (json.map((loc)=>{return loc.company}).filter((v,i,a) => { return a.indexOf(v)===i}).length > 1)
                    }).then((mc) => {
                        fetch((api_url + 'types'), {headers: {Authorization: response.idToken}, method: 'GET'}).then((response) => response.json())
                        .then((json) => setTypes(json.map(res=>{res['label'] = (mc ? res.name + ' - ' + res.company_name : res.name); res['value'] = res.name; return res}))).catch((error) => {
                            alert(error)
                        });
                        fetch((api_url + 'departments'), {headers: {Authorization: response.idToken}, method: 'GET'}).then((response) => response.json())
                        .then((json) => setDepartments(json.map(res=>{res['label'] = (mc ? res.name + ' - ' + res.company_name : res.name); res['value'] = res.name; return res}))).catch((error) => {
                            alert(error)
                        });
                    }).catch((error) => {
                        alert(error)
                    });
                } else {
                    setInstruments([])
                }
            }).catch((error) => {
                console.log(error)
                alert(error)
            });
        }).catch((e) => {
            console.log(e)
            alert(e)
        });

    }

    function handlePdf(row) {
        setLoadingState(prevState => ({ ...prevState, datagrid: true }));
        // if there is a blob saved to the calibration_sheet_blob, fetch that blob using the API & open in new tab
        if(row.calibration_sheet_blob !== '') {
            fetch((api_url + 'blobs/' + row.calibration_sheet_blob), {headers: {Authorization: token.idToken}, method: 'GET'}).then((response) => response.blob())
            .then((blob) => {
                if(blob !== null && blob !== undefined) {
                    setLoadingState(prevState => ({ ...prevState, datagrid: false }));
                    window.open(URL.createObjectURL(blob), '_blank');
                } else {
                    setLoadingState(prevState => ({ ...prevState, datagrid: false }));
                }
            }).catch((error) => {
                console.error(error)
                setLoadingState(prevState => ({ ...prevState, datagrid: false }));
                alert(error)
            });
        } else { // else if there is not a blob already generated, use the generator to generate the blob, then fetch the blob & open in new tab
            fetch((generator_url + 'calibrations_in_house_cal/' + row.id), {headers: {Authorization: token.idToken}, method: 'POST', body: JSON.stringify(row)}) // call the generator
            .then((generator_response) => generator_response.json()) // getting blob id from generator response
            .then((json) => {
                if(json.report_id) {
                    fetch((api_url + 'blobs/' + json.report_id), {headers: {Authorization: token.idToken}, method: 'GET'}).then((response) => response.blob())
                    .then((blob) => {
                    if(blob !== null && blob !== undefined) {
                        setLoadingState(prevState => ({ ...prevState, datagrid: false }));
                        window.open(URL.createObjectURL(blob), '_blank');
                    }
                    }).catch((error) => {
                        setLoadingState(prevState => ({ ...prevState, datagrid: false }));
                        console.error(error)
                    });
                }
            }).catch((error) => {
                setLoadingState(prevState => ({ ...prevState, datagrid: false }));
                console.error(error)
                alert(error)
            })
        }
    }

    const instrument_columns = [
        { field: 'item_number', headerName: 'Item Number', width: 150 },
        { field: 'type', headerName: 'Type', width: 100 },
        { field: 'subtype', headerName: 'Subtype', width: 100 },
        { field: 'due_date', headerName: 'Due Date', width: 200 },
        { field: 'status', headerName: 'Status', width: 100 },
        { field: 'department', headerName: 'Department', width: 200 },
        { field: 'serial_number', headerName: 'SN', width: 200 },
        { field: 'location', headerName: 'Location', width: 200 },
        { field: 'company_name', headerName: 'Company', width: 200 },
    ];

    const calibration_columns = [
        { field: 'manufacturer', headerName: 'Manufacturer', width: 200 },
        { field: 'serial_number', headerName: 'SN', width: 200 },
        { field: 'date_calibrated', headerName: 'Calibrated', width: 125 },
        { field: 'due_date', headerName: 'Due', width: 125},
        { field: 'calibrated_by', headerName: 'Calibrated By', width: 200 },
        { field: 'removed_for_calibration', headerName: 'Removed for Cal', width: 200 },
        { field: 'approved_by', headerName: 'Approved By', width: 200 },
        { field: 'get_pdf', headerName: 'Calibration PDF', width: 200,
            renderCell: (cellValues) => {
                if(cellValues.row.calibration_type === 'In House') {
                    return (
                        <Button
                            style={{ border: '1px solid black' }}
                            variant='primary'
                            onClick={(event) => {
                                event.stopPropagation()
                                handlePdf(cellValues.row)}
                            }
                        >
                            Get PDF
                        </Button>
                    )
                }
            }
        },
    ];

    const handleInstrumentSelection = e => {
        setInstrument(e.row)
        setState({department: e.row?.department !== '' ? {label:e.row?.department, value:e.row?.department} : null, assigned_to: e.row?.assigned_to, out_of_service_date: e.row?.out_of_service_date, removed_for_calibration: '', comments: e.row?.comments})
        setCalibrations([{"manufacturer": "Loading...", "id": 1}])
        fetchInstrumentCalibrations(e.row)
    };

    function fetchInstrumentCalibrations(passedInstrument) {
        fetch((api_url + 'instrument-cals/' + passedInstrument?.id), {headers: {Authorization: token.idToken}, method: 'GET'}).then((response) => {
            if(response.status === 200) {
                return response.json()
            } else if(response.status === 401) {
                alert("You're session has expired, please log out and log back in!")
                instance.logoutRedirect().catch(e => { console.error(e);});
                return false
            }
        }).then((json) => {
            if(json){
                var cal_list = [];
                var initial_cals = json.filter(res=>res.instrument_id === passedInstrument?.id);
                for(var i = 0; i < initial_cals.length; i++) {
                    var date = new Date(initial_cals[i].date_calibrated + 'T00:00:00.000');
                    date.setMonth(date.getMonth()+ parseInt(passedInstrument?.frequency, 10))
                    let temp = {...initial_cals[i], due_date: passedInstrument?.subtype !== 'REF/Cal as Req'? date.toISOString().substring(0, 10) : '',manufacturer: passedInstrument?.manufacturer, serial_number: passedInstrument?.serial_number}
                    cal_list.push(temp)
                }
                setCalibrations(cal_list)
            }
        }).catch((error) => {
                alert(error)
        });
    }

    const handleCalibrationSelection = e => {
        sessionStorage.setItem("instrument", instrument.id);
        sessionStorage.setItem("calibration", e.row?.id);
        if(type !== null) { sessionStorage.setItem("type", JSON.stringify(type)) }
        if(location !== null) { sessionStorage.setItem("location", JSON.stringify(location)) }
        navigate('/view-calibration', { replace: true, state: {redirect: '/'} }); // pass a redirect variable so the view cal page knows where to redirect us back to

    };

    const addCalibration = e => {
        sessionStorage.setItem("instrument", JSON.stringify(instrument));
        if(type !== null) { sessionStorage.setItem("type", JSON.stringify(type)) }
        if(location !== null) { sessionStorage.setItem("location", JSON.stringify(location)) }
        navigate('/add-calibration', { replace: true });
    };

    const editCalibration = e => {
        var latest_cal = '';
        calibrations.forEach(cal => {
            if(latest_cal === '') { latest_cal = cal }
            else if(cal.date_calibrated > latest_cal.date_calibrated) { latest_cal = cal;}
        })
        sessionStorage.setItem("instrument", JSON.stringify(instrument));
        sessionStorage.setItem("latest_calibration", JSON.stringify(latest_cal));
        if(type !== null) { sessionStorage.setItem("type", JSON.stringify(type)) }
        if(location !== null) { sessionStorage.setItem("location", JSON.stringify(location)) }
        navigate('/add-calibration', { replace: true });
    };

    const handleChange = e => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleUpdate = e => {
        var param = {}
        if(state.out_of_service_date !== '' && state.out_of_service_date !== instrument?.out_of_service_date){
            param['status'] = "Inactive";
            param['out_of_service_date'] = state.out_of_service_date;
        }
        if(state?.comments !== instrument?.comments){param['comments'] = state.comments}
        if(state?.assigned_to !== instrument?.assigned_to){param['assigned_to'] = state.assigned_to}
        if(state?.department?.value !== instrument?.department && (state?.department?.value !== undefined || instrument?.department !== '') ){param['department'] = state.department?.value ? state.department?.value : ''}

        if(state.removed_for_calibration !== '') {
            var latest_cal = '';
            if (calibrations.length !== 0) {
                calibrations.forEach(cal => {
                    if(latest_cal === '') { latest_cal = cal }
                    else if(cal.date_calibrated > latest_cal.date_calibrated) { latest_cal = cal;}
                })
            }
            let update = {"removed_for_calibration": state.removed_for_calibration}
            fetch(api_url + 'calibrations/' + latest_cal.id, {headers: {Authorization: token.idToken}, method: 'PUT', body: JSON.stringify(update)}).then((response) => {
                if(response.status !== 200) { alert('Error Updating Removed for Calibration Date!') }
                else {
                    fetchInstrumentCalibrations(instrument)
                    setState(prevState => ({
                        ...prevState,
                        removed_for_calibration: ''
                    }));
                }
            }).catch((error) => {
                alert('Error Updating Removed for Calibration Date: ' + error)
            });

        }

        if(Object.keys(param).length !== 0) {
            fetch(api_url + 'instruments/' + instrument.id, {headers: {Authorization: token.idToken}, method: 'PUT', body: JSON.stringify(param)}).then((response) => {
                if(response.status !== 200) {
                    alert('Error Updating Instrument!')
                } else {
                    if(type !== null) { sessionStorage.setItem("type", JSON.stringify(type)) }
                    if(location !== null) { sessionStorage.setItem("location", JSON.stringify(location)) }
                    setInstrument(null)
                    fetchAllInstruments();
                }
            }).catch((error) => {
                alert('Error Updating Instrument: ' + error)
            });
        }

    };

    const onDropdownChange = (value, action) => {
        if(action.name === 'department') {
            setState(prevState => ({
                ...prevState,
                [action.name]: value
            }));
        } else {
            if(value !== null){
                if(action.name === 'type') {
                    if(location === null) {
                        console.log(value)
                        setInstruments(fullList.filter(val => val.type === value.value && val.company === value.company))
                    } else {
                        setInstruments(fullList.filter(val => (val.type === value.value && val.location === location.value && val.company === value.company && val.company === location.company)))
                    }
                    setType(value)
                } else {
                    if(type === null) {
                        setInstruments(fullList.filter(val => val.location === value.value && val.company === value.company))
                    } else {
                        setInstruments(fullList.filter(val => (val.location === value.value && val.type === type.value && val.company === value.company && val.company === type.company)))
                    }
                    setLocation(value)
                }
            } else {
                if(action.name === 'type') {
                    if(location === null) {
                        setInstruments(fullList)
                    } else {
                        setInstruments(fullList.filter(val => val.location === location.value && val.company === location.company))
                    }
                    setType(null)
                } else {
                    if(type === null) {
                        setInstruments(fullList)
                    } else {
                        setInstruments(fullList.filter(val => val.type === type.value && val.company === type.company))
                    }
                    setLocation(null)
                }
            }
        }
    }

    /**
     * function to handle calling the API for the calibration log
     */
    function getCalibrationLog() {
        setLoadingState(prevState => ({ ...prevState, calLog: true }));
        fetch((generator_url + 'calibrations_log/' + instrument.id + '/gen-fetch'), {headers: {Authorization: token.idToken}, method: 'POST'}).then((response) => {
            if(response.status === 200) {
                return response.blob();
            } else {
                return null;
            }
        }).then((blob) => {
            if(blob !== null && blob !== undefined) {
                setLoadingState(prevState => ({ ...prevState, calLog: false }));
                window.open(URL.createObjectURL(blob), '_blank');
            }
        }).catch((error) => {
            setLoadingState(prevState => ({ ...prevState, calLog: false }));
            console.error(error)
            alert("There was an error generating your calibration log. Please try again later, if the issue persist contact support.")
        })
    }

    /**
     * function to handle calling the API for the full calibration book
     */
    function getCalibrationBook() {
        setLoadingState(prevState => ({ ...prevState, calBook: true }));
        // fetch call to API to get cal book
        fetch((api_url + 'cal-book/' + instrument.id), {headers: {Authorization: token.idToken}, method: 'POST' }).then((response) => {
            if(response.status === 200) {
                return response.blob()
            } else {
                return null;
            }
        }).then((blob) => {
            if(blob !== null && blob !== undefined) {
                const pdfBlob = new Blob([blob], { type: 'application/pdf' })
                const pdfUrl = URL.createObjectURL(pdfBlob)
                window.open(pdfUrl, '_blank')
                setLoadingState(prevState => ({ ...prevState, calBook: false }));
            }
        }).catch((error) => {
            setLoadingState(prevState => ({ ...prevState, calBook: false }));
            console.error(error)
            alert("There was an error generating your calibration book. Please try again later, if the issue persist contact support.")
        })
    }

    function getBlankCalibration() {
        setLoadingState(prevState => ({ ...prevState, blankCal: true }));
        // fetch call to API to get blank cal
        fetch((generator_url + 'calibrations_blank_in_house_cal/' + instrument.id + '/gen-fetch'), {headers: {Authorization: token.idToken}, method: 'POST'}).then((response) => {
            if(response.status === 200) {
                return response.blob();
            } else {
                return null;
            }
        }).then((blob) => {
            if(blob !== null && blob !== undefined) {
                setLoadingState(prevState => ({ ...prevState, blankCal: false }));
                window.open(URL.createObjectURL(blob), '_blank');
            }
        }).catch((error) => {
            setLoadingState(prevState => ({ ...prevState, blankCal: false }));
            console.error(error)
            alert("There was an error generating your blank calibration sheet. Please try again later, if the issue persist contact support.")
        })
    }

    return (
        <>
        <br /><br /><br />
        <h2 id="page-header">Instrument List:</h2>
        <Select className="type-select"
            isClearable={true}
            options={types}
            placeholder="Select a Type..."
            name='type'
            onChange={onDropdownChange}
            value={type}
        />
        {token?.idTokenClaims?.groups.some((element) => process.env.REACT_APP_ADMIN_GROUPS.includes(element)) ? <>
            <Select className="type-select"
                isClearable={true}
                options={locations}
                placeholder="Select a Location..."
                name='location'
                onChange={onDropdownChange}
                value={location}
            />
        </> : <></>}
        <br /><br />
        <div className='landing-grid'>
            <DataGrid
            rows={instruments}
            columns={instrument_columns}
            columnVisibilityModel={{
                company_name: multicompany
            }}
            disableMultipleSelection={true}
            onCellClick={handleInstrumentSelection}
            getRowClassName={(params) => 'instrument-grid-' + params.row.status}
            getCellClassName={(params) => {
                let date = new Date(params.value + 'T00:00:00.000');
                let now = new Date()
                let diff_date = now.getTime() - date.getTime();
                let days = diff_date / (1000 * 3600 * 24)

                if(params.row.status !== 'Inactive') {
                    if (params.field === 'due_date' && days <= -30) {
                        return 'due-date-green';
                    } else if(params.field === 'due_date' && days > -30 && days <= 0) {
                        return 'due-date-yellow';
                    } else if(params.field === 'due_date' && days > 0) {
                        return 'due-date-red';
                    } else {
                        return '';
                    }
                }
            }}
            initialState={{
                sorting: {
                  sortModel: [{ field: 'location', sort: 'asc' }],
                },
            }}/>
        </div>
        <br /><br />
        {instrument !== null ? <div className='instrument-overview'>
            <div className='instrument-left'>
                <h2>Instrument Overview:</h2><br />
                <h4>Item Number: {instrument?.item_number}</h4>
                <h4>Type: {instrument?.type}</h4>
                <h4>Sub Type: {instrument?.subtype}</h4><br /><br />
                <h4>Department: {instrument?.department}</h4>
                {!isViewOnly && <>
                    <Select className="select-fields"
                        id="landing-departments"
                        isClearable={true}
                        options={departments.filter(dept => dept?.company === instrument?.company)}
                        placeholder="Select a department..."
                        name='department'
                        onChange={onDropdownChange}
                        value={state.department}
                    />
                    <br />
                </>}
                <h4>Assigned To: {instrument?.assigned_to}</h4>
                {!isViewOnly && <> <input type="text" id="assigned_to_landing" name="assigned_to" value={state.assigned_to} onChange={handleChange} placeholder='Enter a name...'/><br/><br /> </>}
                <h4>Manufacturer: {instrument?.manufacturer}</h4>
                <h4>Model: {instrument?.model}</h4>
                <h4>Range: {instrument?.range}</h4>
                <h4>Serial Number: {instrument?.serial_number}</h4>
            </div>
            <div className='instrument-right'>
                <h2>Instrument Status: <span style={instrument.status === 'Active' ? {color:'green'} : {color:'red'}}>{instrument.status}</span></h2><br />
                <h4>In Service Date: {instrument.in_service_date}</h4>
                {isViewOnly ? <> <h4>Out of Service Date: {state.out_of_service_date}</h4> </>
                : <> <h4>Out of Service Date: </h4><input type="date" className='overview-dates' name="out_of_service_date" value={state.out_of_service_date} onChange={handleChange}/><br /><br /> </>}
                <h4>Frequency: {instrument.frequency}</h4>
                <h4>Date Calibrated: {instrument.last_calibrated_date}</h4>
                <h4>Due: {instrument.due_date}</h4>
                <h4>Removed for Calibration: </h4>
                {!isViewOnly && <> <input type="date" className='overview-dates' name="removed_for_calibration" value={state.removed_for_calibration} onChange={handleChange} disabled={calibrations.length === 0}/><br /><br /> </>}
                <h4>Comments: </h4>
                <textarea className='instrument-comments' id="instrument-comments" name="comments" value={state.comments} readOnly={isViewOnly} onChange={handleChange} placeholder='Enter a comment...'></textarea><br/>
            </div>
            {!isViewOnly && <> <Button variant="danger" id='update_instrument_button' onClick={handleUpdate}>Update Instrument Information</Button> </>}
        </div> : <></>}
        <br /><br />
        {instrument !== null ? <>
            <div className='landing-grid'>
                <DataGrid
                    loading={loadingState.datagrid || loadingState.calBook || loadingState.calLog}
                    rows={calibrations}
                    columns={calibration_columns}
                    disableMultipleSelection={true}
                    initialState={{
                        sorting: {
                        sortModel: [{ field: 'date_calibrated', sort: 'desc' }],
                        },
                    }}
                    onRowClick={handleCalibrationSelection}
                />
            </div>
            <br />
            {isViewOnly ?
            <>
                <Box ml={-2}>
                    <Grid container columnSpacing={2} sx={{ marginLeft: '10%', width: '80%' }}>
                        <Grid item xs={4}>
                            <Button variant="danger" id='cal-log-button' onClick={getCalibrationLog} disabled={instrument === null || instrument?.status === "Inactive" || calibrations.length === 0}>{loadingState.calLog ? <CircularProgress size={24}/>: 'Calibration Log'}</Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button variant="danger" id='cal-book-button' onClick={getCalibrationBook} disabled={instrument === null || instrument?.status === "Inactive" || calibrations.length === 0}>{loadingState.calBook ? <CircularProgress size={24}/>: 'Get Full Calibration Book'}</Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button variant="danger" id='blank-cal-button' onClick={getBlankCalibration}>{loadingState.blankCal ? <CircularProgress size={24}/>: 'Get Blank Calibration'}</Button>
                        </Grid>
                    </Grid>
                </Box>
            </>
            :
            <>
                <Box ml={-2}>
                    <Grid container columnSpacing={2} sx={{ marginLeft: '10%', width: '80%' }}>
                        <Grid item xs={6}>
                            <Button variant="danger" id='add-cal-button' onClick={addCalibration} disabled={instrument === null || instrument?.status === "Inactive" || isViewOnly}>Add Calibration</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="danger" id='edit-cal-button' onClick={editCalibration} disabled={instrument === null || instrument?.status === "Inactive" || calibrations.length === 0 || isViewOnly}>Edit Calibration</Button>
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container columnSpacing={2} sx={{ marginLeft: '10%', width: '80%' }}>
                        <Grid item xs={4}>
                            <Button variant="danger" id='cal-log-button' onClick={getCalibrationLog} disabled={instrument === null || instrument?.status === "Inactive" || calibrations.length === 0}>{loadingState.calLog ? <CircularProgress size={24}/>: 'Calibration Log'}</Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button variant="danger" id='cal-book-button' onClick={getCalibrationBook} disabled={instrument === null || instrument?.status === "Inactive" || calibrations.length === 0}>{loadingState.calBook ? <CircularProgress size={24}/>: 'Get Full Calibration Book'}</Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button variant="danger" id='blank-cal-button' onClick={getBlankCalibration}>{loadingState.blankCal ? <CircularProgress size={24}/>: 'Get Blank Calibration'}</Button>
                        </Grid>
                    </Grid>
                </Box>
            </>}

        </> : <></>}
        </>
    );
}

export default LandingPage;