import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';
import DataGrid from 'react-data-grid';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { CircularProgress } from '@mui/material';
import { loginRequest } from "../authConfig";
import '../styles/AddCalibration.css';
import { useNavigate } from 'react-router-dom';

function AddCalibration() {
    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [token, setToken] = useState();
    const [state, setState] = useState({date_calibrated: new Date().toJSON().slice(0, 10), calibration_type: "In House", comments: "", measurement_type: null, tech_name: ''});
    const [submit, setSubmit] = useState(false);
    const [instrument, setInstrument] = useState()
    const [holderValues, setHolderValues] = useState({measurement_type: '', cal_point: '', initial: '', adjusted: '', comments: ''})
    const [calValues, setCalValues] = useState([])
    const [mtype, setMtype] = useState(null)
    const [standards, setStandards] = useState([])
    const [instrumentStandards, setInstrumentStandards] = useState([])
    const [standardUsed, setStandardUsed] = useState(null)
    const [selectedFile, setSelectedFile] = useState(null)
    const [fileIsSelected, setFileIsSelected] = useState(false)
    const [update, setUpdate] = useState(false)
    const [calId, setCalId] = useState('')
    const navigate = useNavigate();
    
    const api_url = 'https://func-bv-calibrations.azurewebsites.net/api/calibrations/';

    const columns = [
        { key: 'measurement_type', name: 'Measurement Type' },
        { key: 'cal_point', name: 'Cal Point' },
        { key: 'initial', name: 'Measurement' },
        { key: 'adjusted', name: 'Adjusted' },
        { key: 'comments', name: 'Comments' },
        { key: 'remove', name: "Remove"}
    ];

    const cal_columns = [
        { key: 'standard_used', name: 'Standard Used' },
        { key: 'serial_number', name: 'Standard SN' },
        { key: 'rated_accuracy', name: 'Rated Accuracy' },
        { key: 'last_calibrated_date', name: 'Last NIST Cal Date' },
        { key: 'remove', name: "Remove"}
    ];

    React.useEffect(() => {
        window.scrollTo(0, 0);
        if(isAuthenticated) { 
            RequestAccessToken();
            if(sessionStorage.getItem('latest_calibration') !== null) {
                let cal = JSON.parse(sessionStorage.getItem('latest_calibration'));
                setState({date_calibrated: cal.date_calibrated, calibration_type: cal.calibration_type, comments: cal.comments, measurement_type: null, tech_name: cal.calibrated_by})
                let cals = cal?.calibration_values ? JSON.parse(cal?.calibration_values) : []
                cals.forEach(row => row.remove = <span style={{color: 'red'}}>X</span>)
                setCalValues(cals)
                let strds = cal?.standards_used ? JSON.parse(cal?.standards_used) : []
                strds.forEach(row => row.remove = <span style={{color: 'red'}}>X</span>)
                setStandards(strds)
                sessionStorage.removeItem('latest_calibration');
                setUpdate(true)
                setCalId(cal?.id)
            }
            setInstrument(JSON.parse(sessionStorage.getItem('instrument')));
            if(sessionStorage.getItem('instrument') === null){ navigate('/', { replace: true }); }
            sessionStorage.removeItem('instrument');
        }
        // eslint-disable-next-line
      }, [isAuthenticated]);

      // This hook takes effect when there is an instrument specified and the user is an admin so that the standards used are filtered based on the location that the instrument is at
      React.useEffect(() => {
        if(instrument !== null && instrumentStandards.length !== 0){
            setInstrumentStandards(instrumentStandards.filter(res=>res.type === 'Standard' && res.status === "Active" && res.location === instrument.location && res.company === instrument.company))
        }
      }, [instrument, instrumentStandards])
  
    function RequestAccessToken() {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };

        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then((response) => {
            setToken(response);
            let tempToken = response;

            fetch((api_url + 'instruments'), {headers: {Authorization: tempToken.idToken}, method: 'GET'}).then((response) => response.json())
            .then((json) => {
                setInstrumentStandards(json.filter(res=>res.type === 'Standard' && res.status === "Active"))
            }).catch((error) => {
                alert(error)
            });
        }).catch((e) => {
            instance.acquireTokenPopup(request).then((response) => {
                setToken(response);
            });
        });
    }

    const handleChange = e => {
        const { name, value } = e.target;

        if(name === "calibration_type"){
            setState(prevState => ({...prevState, [name]: value, tech_name: ''}));
        } else {
            setState(prevState => ({...prevState, [name]: value}));
        }
        
    };

    const handleHolderChange = e => {
        const { name, value } = e.target;
        setHolderValues(prevHolderValues => ({
            ...prevHolderValues,
            [name]: value
        }));
    };

    const onDropdownChange = (value, action) => {
        if(action.name === 'measurement_type') {
            setMtype(value)
            setHolderValues(prevHolderValues => ({
                ...prevHolderValues,
                [action.name]: value?.value
            }));
        } else if(action.name === 'standards_used') {
            var value_dict = {standard_used: value.label, serial_number: value.serial_number, rated_accuracy: value.rated_accuracy, last_calibrated_date: value.last_calibrated_date, remove: <span style={{color: 'red'}}>X</span>}
            var contain = false;
            standards.forEach(element => {
                if(element.standard_used === value_dict.standard_used) {contain = true}
            })
            if(!contain) {
                var holderArray = standards.concat(value_dict)
                setStandards(holderArray);
                setStandardUsed(null)
            }
        }
    }

    const addToGrid = (value, action) => {
        var holderArray = calValues.concat(holderValues)
        holderArray[holderArray.length - 1].remove = <span style={{color: 'red'}}>X</span>
        setCalValues(holderArray);
        setHolderValues({measurement_type: holderValues.measurement_type, cal_point: '', initial: '', adjusted: '', comments: ''})
    }

    const removeFromStrdGrid = (value, action) => {
        if(action.key === 'remove') {
            var holderArray = standards.filter(row => (row.standard_used !== value.standard_used || row.serial_number !== value.serial_number || row.rated_accuracy !== value.rated_accuracy || row.last_calibrated_date !== value.last_calibrated_date))
            setStandards(holderArray)
        }
    }

    const removeFromCalGrid = (value, action) => {
        if(action.key === 'remove') {
            var holderArray = calValues.filter(row => (row.measurement_type !== value.measurement_type || row.cal_point !== value.cal_point || row.initial !== value.initial || row.adjusted !== value.adjusted || row.comments !== value.comments))
            setCalValues(holderArray)
        }
    }

    const submitCalibration = e => {
        setSubmit(true)
        RequestAccessToken()
        let holderCalValues = [...calValues]
        holderCalValues.forEach(r => delete r.remove)
        let holderStrdValues = [...standards]
        holderStrdValues.forEach(r => delete r.remove)
        var param = {"instrument_id": instrument?.id, "date_calibrated": state.date_calibrated, "calibration_type": state.calibration_type, "comments": state.comments, "calibration_values": JSON.stringify(holderCalValues), "standards_used": JSON.stringify(holderStrdValues), "calibrated_by": state.tech_name === '' ? token.idTokenClaims.name : state.tech_name, "approved_by": token.idTokenClaims.name}
        fetch((api_url + 'calibrations'), {headers: {Authorization: token.idToken}, method: 'POST', body: JSON.stringify(param)}).then((response) => {
            if(response.status === 200) {
                setState({date_calibrated: new Date().toJSON().slice(0, 10), calibration_type: "In House", comments: "", measurement_type: null, tech_name: ''});
                setMtype(null);
                setCalValues([]);
                setStandards([]);
                if(instrument.last_calibrated_date === '' || param.date_calibrated > instrument.last_calibrated_date) {
                    fetch((api_url + 'instruments/' + instrument.id), {headers: {Authorization: token.idToken}, method: 'PUT', body: JSON.stringify({last_calibrated_date: param.date_calibrated})}).then((response) => { 
                        if(response.status !== 200) {
                            alert('Error Updating Instrument!')
                        }
                    })
                }
                setSubmit(false);
                return response.json()
            } else if(response.status === 401) {
                alert("You're session has expired, please log out and log back in!")
                instance.logoutRedirect().catch(e => { console.error(e);});
            } else {
                alert('Error! Try again later.')
                setSubmit(false)
                return false
            }
        }).then((json) =>{
            if(json !== false && fileIsSelected === true){
                const formData = new FormData();
                formData.append('File', selectedFile);

                fetch((api_url + 'blobs/' + json.id), {headers: {Authorization: token.idToken}, method: 'POST', body:formData }).then((response) => { 
                    if(response.status !== 200) {
                        alert('Error Updating Instrument!')
                    }
                }).then(() => navigate('/', { replace: true }))
            } else {
                navigate('/', { replace: true });
            }
        }).catch((error) => {
            setSubmit(false)
            alert(error)
        });
    };

    const updateCalibration = e => {
        setSubmit(true)
        RequestAccessToken()
        let holderCalValues = [...calValues]
        holderCalValues.forEach(r => delete r.remove)
        let holderStrdValues = [...standards]
        holderStrdValues.forEach(r => delete r.remove)
        var param = {"calibration_sheet_blob": '', "instrument_id": instrument?.id, "date_calibrated": state.date_calibrated, "calibration_type": state.calibration_type, "comments": state.comments, "calibration_values": JSON.stringify(holderCalValues), "standards_used": JSON.stringify(holderStrdValues), "calibrated_by": state.tech_name === '' ? token.idTokenClaims.name : state.tech_name, "approved_by": token.idTokenClaims.name}
        fetch((api_url + 'calibrations/' + calId), {headers: {Authorization: token.idToken}, method: 'PUT', body: JSON.stringify(param)}).then((response) => {
            if(response.status === 200) {
                setState({date_calibrated: new Date().toJSON().slice(0, 10), calibration_type: "In House", comments: "", measurement_type: null, tech_name: ''});
                setMtype(null);
                setCalValues([]);
                setStandards([]);
                fetch((api_url + 'instruments/' + instrument.id), {headers: {Authorization: token.idToken}, method: 'PUT', body: JSON.stringify({last_calibrated_date: param.date_calibrated})}).then((response) => { 
                    if(response.status !== 200) {
                        alert('Error Updating Instrument!')
                    }
                })
                setSubmit(false);
                setUpdate(false)
                return response.json()
            } else if(response.status === 401) {
                alert("You're session has expired, please log out and log back in!")
                instance.logoutRedirect().catch(e => { console.error(e);});
            } else {
                alert('Error! Try again later.')
                setSubmit(false)
                return false
            }
        }).then((json) =>{
            if(json !== false && fileIsSelected === true){
                const formData = new FormData();
                formData.append('File', selectedFile);

                fetch((api_url + 'blobs/' + json.id), {headers: {Authorization: token.idToken}, method: 'POST', body:formData }).then((response) => { 
                    if(response.status !== 200) {
                        alert('Error Updating Instrument!')
                    }
                }).then(() => navigate('/', { replace: true }))
            } else {
                navigate('/', { replace: true });
            }
        }).catch((error) => {
            setSubmit(false)
            alert(error)
        });
    };

    const changeHandler = (event) => {
		setSelectedFile(event.target.files[0]);
		setFileIsSelected(true);
	};
  
    return (
        <>  
            <br /><br /><br /><br />
            <div className='cal-container'>
                <div className='left-flex'>
                    <h2>Instrument Information:</h2><br />
                    <h4>Item Number: {instrument?.item_number}</h4>
                    <h4>Item Type: {instrument?.type}</h4>
                    <h4>Item Subtype: {instrument?.subtype}</h4>
                    <h4>Department: {instrument?.department}</h4>
                    <h4>Calibration Frequency: {instrument?.frequency}</h4>
                </div>
                <div className='right-flex'>
                    <h2>Instrument Specifics:</h2><br />
                    <h4>Manufacturer: {instrument?.manufacturer}</h4>
                    <h4>Model: {instrument?.model}</h4>
                    <h4>Range: {instrument?.range}</h4>
                    <h4>Serial Number: {instrument?.serial_number}</h4>
                    <h4>Assigned to: {instrument?.assigned_to}</h4>
                </div>

            </div>
            <br />
            <div className='cal-details'>
                <div className='cal-details-parts'>
                    <h5 className="detail-label">Date Calibrated:</h5>
                    <input type="date" id="date_calibrated" name="date_calibrated" value={state.date_calibrated !== '' ? state.date_calibrated: ""} onChange={handleChange} /><br/>
                </div>
                <div className='cal-details-spacer'></div>
                <div className='cal-details-parts'>
                    <h5 className="detail-label">Calibration Type:</h5>
                    <div className='keep-buttons-together'>
                        <input type="radio" id="calibration_type" name="calibration_type" value="In House" checked={state.calibration_type === 'In House'} onChange={handleChange}/><h5 className="detail-label-1">In House</h5>
                        <input type="radio" id="calibration_type" name="calibration_type" value="Third Party" checked={state.calibration_type === 'Third Party'} onChange={handleChange} /><h5 className="detail-label">Third Party</h5>
                    </div>
                </div>
            </div>
            <br />
            <div className='comment-container'>
                <h2>Calibration Specific Comments:</h2>
                <textarea className='comment-box' id="comments" name="comments" value={state.comments} onChange={handleChange}></textarea>
                { state.calibration_type === "Third Party" ?<>
                        <br /><h2>Calibrating Technician Name: </h2>
                        <input type="text" id="tech_name" name="tech_name" value={state.tech_name} onChange={handleChange} placeholder='Enter tech name...'/>
                </>:<></>}
            </div>
            <br />
            { state?.calibration_type === "In House" ?
            <>
                <div className='comment-container'>
                    <h2>Calibration Values:</h2>
                    <Select className="measurement_type" 
                        isClearable={true} 
                        options={[
                            {label:'Standard', value: 'Standard'},
                            {label:'Caliper OD', value: 'Caliper OD'},
                            {label:'Caliper ID', value: 'Caliper ID'},
                            {label:'Caliper Depth', value: 'Caliper Depth'}
                        ]}
                        placeholder="Select a type..."  
                        name='measurement_type'
                        onChange={onDropdownChange} 
                        value={mtype}
                    />
                    <input type="text" id='holder-fields' name="cal_point" value={holderValues.cal_point} onChange={handleHolderChange} placeholder='Cal Point...'/>
                    <input type="text" id='holder-fields' name="initial" value={holderValues.initial} onChange={handleHolderChange} placeholder='Measurement...'/>
                    <input type="text" id='holder-fields' name="adjusted" value={holderValues.adjusted} onChange={handleHolderChange} placeholder='Adjusted...'/>
                    <input type="text" id='holder-fields' name="comments" value={holderValues.comments} onChange={handleHolderChange} placeholder='Comments...'/>
                    <br />
                    <Button className='table-fillers' variant='danger' onClick={addToGrid}>Add Values</Button>
                    <br /><br />
                    <DataGrid className="rdg-light" height="200px" columns={columns} rows={calValues} onRowClick={removeFromCalGrid}/>
                </div>
                <br />
                <div className='comment-container'>
                    <h2>Standards Used:</h2>
                    <Select className="standards_used" 
                        isClearable={true} 
                        options={instrumentStandards.map(val => {return {...val, "label": val?.item_number, "value": val?.item_number}})}
                        placeholder="Select a standard..."  
                        name='standards_used'
                        onChange={onDropdownChange}
                        value={standardUsed}
                    />
                    <br /><br />
                    <DataGrid className="rdg-light" height="200px" columns={cal_columns} rows={standards} onRowClick={removeFromStrdGrid}/>
                </div>
            </>:<>
                <div className='comment-container'>
                    <h2>Upload Cal Sheet:</h2>
                    <input type="file" id="pdfUpload" name="pdfUpload" accept=".pdf" onChange={changeHandler}/>
                </div>
            </>}
            <br />
            { !update ? 
                <Button className='submit-button' variant='danger' onClick={submitCalibration} disabled={instrument === null || instrument === undefined || (state.calibration_type === 'Third Party' && state.tech_name === '')}>{submit ? <CircularProgress size={24}/>: 'Submit Calibration'}</Button> :
                <Button className='submit-button' variant='danger' onClick={updateCalibration} disabled={instrument === null || instrument === undefined || (state.calibration_type === 'Third Party' && state.tech_name === '')}>{submit ? <CircularProgress size={24}/>: 'Update Calibration'}</Button> 
            }
        </>
    );
}

export default AddCalibration;