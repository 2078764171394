export const msalConfig = {
    auth: {
      clientId: "7d31830f-0186-4a94-93e4-34874f236b77", // https://portal.azure.com/#view/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/~/Overview/quickStartType~/null/sourceType/Microsoft_AAD_IAM/appId/7d31830f-0186-4a94-93e4-34874f236b77/objectId/57ca9c43-a679-4e08-b6a6-c9ce8802e81a/isMSAApp~/false/defaultBlade/Overview/appSignInAudience/AzureADMyOrg/servicePrincipalCreated~/true
      authority: "https://login.microsoftonline.com/1dce4791-8eb8-4556-9e49-ce4975223730", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      redirectUri: "https://bvcal.iss-digital.com",
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   scopes: ["User.Read"]
  };

  export const logoutRequest = {
    postLogoutRedirectUri: "https://bvcal.iss-digital.com"
  }

  export const getAccessToken = async (msal) => {
    // if the user is already logged in you can acquire a token
    if (msal.accounts[0]) {
      var tokenRequest = { scopes: ["user.read"], account: msal.accounts[0] };
      return await msal.instance.acquireTokenSilent(tokenRequest).catch(async err => { 
        if(err.name !== "InteractionRequiredAuthError"){     
          return await msal.instance.acquireTokenPopup(tokenRequest).catch(async err => { console.log('The following error has been caught:\n' + err); });
        } else {
          console.log('The following error has been caught:\n' + err);
        } 
      });
    } else {
      console.log('WARNING: You may not acquire an access token unless you are authenticated. Please log in using your ISS credentials!'); // user is not logged in, you will need to log them in to acquire a token
    }
  }