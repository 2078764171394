import React from "react";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import Button from "react-bootstrap/Button";

/**
 * Renders a button which, when selected, will open a popup for login
 */
export const AuthButton = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

  function handleClick(instance) {
    if (isAuthenticated) {
      sessionStorage.clear();
      instance.logoutRedirect().catch(e => {
        console.error(e);
      });
    } else {
      instance.loginRedirect(loginRequest).catch(e => { // don't use pop-up, the browser opens it in a new tab.
        console.error(e);
      });
    }
  }

  return (
    <Button variant="outline-secondary" onClick={() => handleClick(instance)}>{isAuthenticated ? "Sign Out" : "Sign In"}</Button>
  );
}